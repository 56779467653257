<template>
  <div>
    <base-header type="gradient-red" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row>
        <b-col>
          <card>
            <datatable
              classe="table table-bordered table-striped"
              :url="datatableUrl"
              ref="datatable"
              :btnExport="false"
              @create="$router.push({ name: 'companies-create' })"
            >
              <div slot-scope="{ actions }">
                <b-dropdown boundary="viewport" size="sm" text="Actions" variant="primary">
                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'companies-review',
                        params: { id: actions.id },
                      })
                    "
                  >
                    <i class="fa fa-th-list p-1"></i> Review
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="['E', 'D'].includes(actions.status)"
                    @click.prevent="changeStatus(actions.status === 'E' ? 'D' : 'E', actions.id)"
                  >
                    <i class="fa fa-toggle-on p-1"></i> Change Status
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="actions.messages > 0"
                    @click="
                      $router.push({
                        name: 'list_change_companies',
                        params: { id: actions.id },
                      })
                    "
                  >
                    <i class="fa fa-sort-amount-asc p-1"></i> Change History
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <template #status="{ data }">
                <small :class="`badge ${badgeColor(data)}`">
                  {{ nameStatus(data) }}
                </small>
              </template>
              <template #image="{ data }">
                <img :src="resource(data)" width="70" class="rounded" :alt="data" />
              </template>
            </datatable>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import CompaniesService from "features/companies/services/CompaniesService";
import { resource } from "helpers";

export default {
  name: "Companies",
  data: () => ({
    datatableUrl: CompaniesService.baseUrl,
    resource,
  }),

  methods: {
    changeStatus(status, id) {
      this.$swal({
        title: "Are you sure?",
        text: "This action changes the item status to disabled.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          CompaniesService.changeStatus(status, id).then(() => {
            this.$refs.datatable.refresh();
            this.$swal("Changed!", "Status changed", "success");
          });
        }
      });
    },
    nameStatus(status) {
      switch (status) {
        case "I":
          return "In Review";
        case "W":
          return "Wait For Payment";
        case "R":
          return "Refused";
        case "E":
          return "Enabled";
        default:
          return "Disabled";
      }
    },
    badgeColor(status) {
      switch (status) {
        case "I":
          return "badge-info";
        case "W":
          return "badge-warning";
        case "R":
          return "badge-black";
        case "E":
          return "badge-success";
        default:
          return "badge-danger";
      }
    },
  },
};
</script>
